import React from "react";
import logo from "./logo.svg";

function Logo() {
    return (
        <div style={{width: "90vw", maxWidth: "500px"}}>
            <img src={logo} alt="logo" style={{width: "100%", maxHeight:"80vh", minHeight: "300px"}}/>
        </div>
    );
}

export default Logo;

import React from 'react';
import './App.css';
import Header from "./header/Header";
import Body from "./body/Body";

function App() {
    return (
        <div className="App">
            <Header/>
            <Body/>
        </div>
    );
}

export default App;

import React from "react";
import "./Body.css"

function Body() {
    return (
        <div className="Body">
            <p>Die Honig Jungs aus Moers.</p>
        </div>
    );
}

export default Body;

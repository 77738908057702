import React from "react";
import Logo from "../logo/Logo";

function Header() {
    return (
        <div className="Header">
            <Logo/>
        </div>
    );
}

export default Header;
